<template>
  <span class="icon-text flex flex-middle gap-s" :class="wrapperClass">
    <icon v-if="icon && iconPosition === 'start'" :icon="icon" :size="iconSize" class="icon-text__icon"></icon>
    <span class="icon-text__text" :class="textClass">
      <slot>{{ text ? translate(text) : "" }}</slot>
    </span>
     <icon v-if="icon && iconPosition === 'end'" :icon="icon" :size="iconSize" class="icon-text__icon"></icon>

  </span>
</template>
<script>
import IconTextMixin from "@/client/extensions/mixins/baseComponents/html/iconText.js";

export default {
  mixins: [IconTextMixin],
  props: {},
  computed: {
    wrapperClass() {
      let result = [];
      result.push("gap-" + this.gap);
      result.push("flex-" + this.iconAlign);
      return result;
    },
  },
};
</script>
<style scoped lang="scss"></style>
