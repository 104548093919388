export default {
  props: {
    icon: {
      type: [String, Boolean],
      default: false,
    },
    iconSize: {
      type: [Number, Boolean],
      default: false,
    },
    iconAlign: {
      type: String,
      default: "middle",
    },
    iconClass: {
      type: String,
      default: "",
    },
    textClass: {
      type: String,
      default: "",
    },
    text: {
      type: [String, Boolean],
      default: "",
    },
    gap: {
      type: [String],
      default: "s",
    },
    iconPosition: {
      type: String,
      default: "start",
    },
  },
};
